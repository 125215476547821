<template>
  <div class="container">
    <RoleCard theme="student">
      <template #label_img>
        <img
          src="@/assets/images/reg_school.svg"
          alt="иконка" />
      </template>
      <template #label>Приглашение в НТО</template>
      <template #body>
        <div v-if="inviter">
          <div>
            <div class="row">
              <div
                v-if="inviter.avatar"
                class="col-xs-3 col-sm-1 col-md-1">
                <BaseAvatar
                  class="mb-s"
                  :image="inviter.avatar" />
              </div>
              <div class="col-md-10 col-sm-10 col-xs-12">
                <p class="mb-m">
                  {{ inviter.short_name }} приглашает вас принять участие
                  в&nbsp;Олимпиаде НТИ.
                </p>

                <div v-if="isMentor || isParticipant">
                  <p class="color-error mb-m">
                    Вы не можете принять это приглашение, так как уже
                    зарегистрировались на Олимпиаду.
                  </p>
                  <BaseButton
                    tag="router-link"
                    :to="{ name: isMentor ? 'mentor-index' : 'user-index' }"
                    >ОК, Понятно</BaseButton
                  >
                </div>
                <div v-else>
                  <BaseButton
                    tag="a"
                    :href="loginLink"
                    class="mb-s"
                    @click="loginClick"
                    >Принять приглашение</BaseButton
                  >&emsp;
                  <BaseButton
                    theme="reg"
                    tag="router-link"
                    to="/"
                    >Отмена</BaseButton
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <p class="mb-l">Неверная ссылка.</p>
          <BaseButton
            theme="reg"
            tag="router-link"
            to="/"
            >На главную</BaseButton
          >
        </div>
      </template>
    </RoleCard>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RoleCard from "@/components/registration/RoleCard.vue";
import { talentRequest } from "@/services/api";
import { userSerializer } from "@/utils/user";
export default {
  name: "InviteFriend",
  components: { RoleCard },
  metaInfo() {
    return {
      title: "Вас приглашают принять участие в НТО",
    };
  },
  data() {
    return {
      inviter: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      isMentor: "user/isMentor",
      isParticipant: "user/isParticipant",
    }),
    loginLink() {
      const { code, user } = this.$route.params;
      const path = `/registration?code=${code}&invited_by=${user}`;
      return `${
        this.$store.state.clientApiPrefix
      }/auth/go?next=${encodeURIComponent(path)}`;
    },
  },
  mounted() {
    this.getUser();
  },
  methods: {
    loginClick() {
      window.dataLayer?.push({
        event: "nto_auth_with_talant_oauth_suggest",
        eventCategory: "nto_auth_with_talant",
        eventAction: "oauth_suggest",
        eventLabel: "invite_type=invite_from_friend",
      });
    },
    async getUser() {
      const user = this.$route.params.user;
      const { data } = await talentRequest({
        method: "GET",
        url: `/api/users/${user}/`,
      });
      if (data.id) {
        this.inviter = userSerializer(data);
      }
    },
  },
};
</script>

<style></style>
